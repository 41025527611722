* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Helvetica";
  background-color: rgb(236, 236, 238) !important;
}
.page {
  font-size: 14px;
  justify-content: center;
  align-items: center;
  background-color: rgb(236, 236, 238) !important;
}
/*.container {
    width: 400px !important;
    background-color: white;
     padding: 2rem 4rem; 
    padding-bottom: 2rem;
    border-radius: 0.5rem;
     border-top: rgb(80, 98, 255) 0.5rem solid; 
  }*/

#login form {
  border: 1px solid #ccc;
  padding: 40px;
  background: #fff;
  width: 33.33333%;
  margin: 50px auto;
}

h2 {
  margin: 1rem 0;
}

form {
  display: flex;
  flex-direction: column;
  /* gap: 0.2rem; */
}

form div {
  display: flex;
  flex-direction: column;
  /* gap: 0.3rem; */
}

input {
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: 1px solid gray;
  font-size: 1.1rem;
}
#login form div {
  padding-bottom: 10px;
  margin-bottom: 14px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
}
.row.logo_style {
  width: 300px;
  margin: 20px auto;
  text-align: center;
}
#login h2 {
  text-align: center;
  padding-bottom: 20px;
}
#login label {
  padding-bottom: 8px !important;
}
#login span {
  text-align: center;
}
.logo_style img {
  width: auto;
}

#login button {
  background: #4c8ae6 !important;
  border: 1px solid #4c8ae6 !important;
  font-weight: 600 !important;
  outline: none !important;
  color: #fff;
  border-radius: 38px !important;
  padding: 10px 18px !important;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  height: 56px !important;
}

span a {
  text-decoration: none;
  color: rgb(80, 98, 255);
}

.private {
  height: 100vh;
  width: 100vw;
  background-color: rgb(80, 98, 255);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 3rem;
  flex-direction: column;
  gap: 1rem;
}

.private button {
  background-color: black;
  padding: 2rem 10rem;
  font-size: 4rem;
  border-radius: 2rem;
}

#act_tests {
  background-color: #FEFEFE;
  border: 5px solid #000;
  padding: 5% !important;
  margin: 1% !important;
  width: 98% !important;
  overflow: hidden;
}
#act_tests tr td
{
  padding:5px 0px;
}
#act_tests label
{
    float:left;
    margin:0px 5px;
}
#act_tests input[type="radio"] {
    width: 15px;
    height: 15px;
    border-radius: 10px;
    border: 2px solid #1FBED6;
    background-color: white;
    -webkit-appearance: none; /*to disable the default appearance of radio button*/
    -moz-appearance: none;
  }
  
  #act_tests  input[type="radio"]:focus { /*no need, if you don't disable default appearance*/
    outline: none; /*to remove the square border on focus*/
  }
  
  #act_tests input[type="radio"]:checked { /*no need, if you don't disable default appearance*/
    background-color: #1FBED6;
  }
  
  #act_tests input[type="radio"]:checked ~ span:first-of-type {
    color: #1FBED6;
  }
  
  .selected-radio {
    background-color: #abcdef; /* Change this to the desired background color */
    color: white; /* Change text color */
    /* Add other styling as needed */
  }

  
  .selected-radio.wrong-ans{
    background-color: red !important; /* Change this to the desired background color */
    border: red !important; /* Change text color */
    /* Add other styling as needed */
  }.correct-ans{
    background-color: green !important; /* Change this to the desired background color */
    border: green !important ; /* Change text color */
    /* Add other styling as needed */
  }

  #act_tests label span:first-of-type {
      position: relative;
      font-size: 12px;
      color: #1FBED6;
      top: -5px;
      font-weight: bold;
      padding-left: 5px !Important;
  }
  
  #act_tests label span {
    position: relative;
    top: -12px;
  }
  .section_title
  {
    font-size: 18px;
    font-weight:bold;
    padding-left: 20px;
  }
  

  .border.highlighted
  {
    border:2px solid blue !important;
  }
  @tailwind base;
  @tailwind components;
  @tailwind utilities;