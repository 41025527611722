.App {
  font-family: sans-serif;
  text-align: center;
}
.loading-screen {  
  text-align: center; /* Centers text inside the loading screen */
  font-size: 3rem;
}
.app-content.pt-3
{
    min-height: 100vh;
}
.loading-screen h1 {
  font-size: 2rem;
  color: #343a40; /* Adjust for contrast */
}
.radio-btn-container {
  display: inline-flex;
  width: 32%;
  background: #c9e3f9;
  margin-left: 1%;
  margin-top: 2%;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.radio-btn-container div {
  display: inline-flex;
  background: #c9e3f9; 
  align-items: center;
}
.tp_mainWrapper,
.tp_outerWrapper {
  display: inline-block !important;
  height: max-content !important;
  margin-right: 5px !important;
}
.tp_bottomBarWrapper {
  display: none !important;
}
.tp_actionsContainer {
  display: none !important;
}
.span_check_style {
  width: 120px !important;
  display: inline-block !important;
  padding-left: 10px !important;
  margin-top: 5px !important;
}
.strip_scores {
  position: absolute;
  z-index: 100000;
  height: 2px;
  background: gray;
  overflow: hidden;
  margin-top: 15px;
  width: 94%;
  margin-left: -5px;
}
.radio-btn {
  margin: 10px;
  padding: 0px;
  border-radius: 25px;
  background: white;
  font-weight: bold;
  font-size: 14px;
  box-shadow: 0px;
  text-align: center;
  width: 1.5rem;
  height: 1.5rem;
  align-items: center; 
  justify-content: center;
}
form div {
  display: block;
  flex-direction: initial;
  /* gap: 0.3rem; */
  /* margin-top: 8px; */
}
input[type="radio"],
input[type="checkbox"] {
  background: red;
  width: 21px;
  height: 21px;
  display: block;
}
.radioLabel {
  position: absolute;
  margin-top: 0px;
  margin-left: 0px;
}
.radio-btn input[type="radio"], .radio-btn input[type="checkbox"] {
  background: red;
  width: 40px;
  height: 30px;
  display: block;
}
.number {
  text-align: center;
  vertical-align: middle; 
  width: 40px !important;
  font-size: 13px;
  font-weight: bold;
  padding: 0px;
  justify-content: center; 
}

/*-----------------PDF SCORE----------------*/

.pdf_score_view #main-wrapper {
  background-color: #fff;
}
.pdf_score_view #main-wrapper .content-wrapper {
  background-color: #fff;
  padding: 0px;
}
.pdf_score {
  width: 750px;
  margin: 0px auto;
  background: #fff;
  padding: 20px 0px;
  page-break-after: always;
}
.pdf_score .logo {
  margin-bottom: 20px;
}
.page2_answers,
.page2_choicecs {
  width: 48%;
  float: left;
  display: flex;
  align-items: start;
  font-size: 10px;
  line-height: 22px;
  border: 2px solid #f0f0f0;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  padding: 0px;
  margin-right: 4%;
  text-align: center;
}
.page2_choicecs {
  margin-right: 0px;
}
.vertical-text {
  writing-mode: vertical-lr;
}

.rotate-90 {
  writing-mode: vertical-rl;
  /*height: 8rem;*/
  transform: rotate(180deg);
  /*text-align: left;*/
  width: 0.75rem;
  padding-left: 0.3em;
  padding-right: 0.7rem;
} 
@media (max-height: 600px) {
  .botoms {
    margin-bottom: 100px; /* Reset margin-bottom for heights greater than 600px */
  }
}
@media print {
  .ans_titles {
    border-right: 1px solid #000;
    height: 100px;
    white-space: nowrap;
    writing-mode: sideways-lr;
    text-align: left;
    vertical-align: bottom;
    padding-left: 20px;
    padding-bottom: 10px;
  }
  .ans_titles:last-child {
    border-right: 0px !important;
  }
}
/*.ans_titles {
  border-right: 1px solid #000;
  height: 100px;
  white-space: nowrap;
  writing-mode: sideways-lr;
  text-align: left;
  vertical-align: bottom;
  padding-left: 20px;
  padding-bottom: 10px;
}*/
.ans_titles {
  border-right: 1px solid #000;
  text-align: center;
  padding-inline: 10px;
}
.ans_titles:last-child {
  border-right: 0px !important;
}
.page2_choicecs .choicetitles {
  text-align: left;
  padding: 5px 20px;
  font-size: 11px;
  line-height: 11px;
}
.module_title {
  background: #ed197e;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding: 5px 0px;
  font-size: 14px;
  letter-spacing: 1px;
}
.pdf_score .logo img {
  width: 200px;
}
.pdf_score h1 {
  text-align: center;
  text-decoration: underline;
  padding-bottom: 10px;
  font-size: 35px;
  font-weight: bold;
  letter-spacing: 1px;
  text-underline-offset:10px;
}
.pdf_score h2 {
  text-align: center;
  padding-bottom: 10px;
  font-size: 25px;
  font-weight: bold;
  margin: 0px;
}
.pdf_score h5 {
  text-align: center;
  padding-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  margin: 0px;
}

.crcount,
.wrcount,
.orcount {
  padding: 0px;
  border-radius: 2px;
  width: 20px;
  height: 15px;
  text-align: center;
  color: #fff;
  font-size: 10px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 2px 10px 2px 0px;
}
.crcount {
  background: green;
}
.wrcount {
  background: red;
}

.orcount {
  background: orange;
}
.mcat_sat {
  padding-left: 40px;
  justify-content: center;
  align-items: center;
}
.page3_strength_weekness {
  font-size: 13px;
  line-height: 25px;
}
.page3_strength_weekness .scat_percent
{
  background-color: #979393;
  border-radius: 2px;
  width: auto;
  height: 13px;
  text-align: center;
  color: #fff;
  font-size: 10px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  float: left;
  margin: 0px 10px 0px 0px;
  padding: 0px 5px;
}
.page3_strength_weekness.act .scat_percent
{
  
  font-size: 10px;
  height: 20px;
}

.page3_strength_weekness .rpad .scat_percent {
  background-color: #39327a;
}

.rpad {
  padding: 0px;
}
.page3_strength_weekness
{
  font-size: 10px;
}
.page3_strength_weekness.act
{
  font-size: 11px;
}

.page2_block {
  width: 32%;
  border: 1px solid #53c0ee;
  text-align: center;
  border-radius: 2px;
  margin-right: 2%;
  padding: 2px 10px;
  justify-content: center;
  display: flex;
  color: #149ed9;
  font-weight: bold;
  font-size: 14px;
}
.page2_block:last-child {
  margin-right: 0px;
}

.page2_block .col-left {
  float: left;
  text-align: left;
  width: 60%;
  border-right: 2px solid #f7f5f5;
}
.page2_block .col-right {
  float: right;
  width: 40%;
  text-align: right;
}

.page2_block .total_score {
  font-size: 40px;
  color: #3a2f7c;
  font-weight: bold;
  line-height: 30px;
}

.pdf_score .row {
  display: flex;
  padding: 10px 0px;
  margin: 0px;
}
.pdf_score .page3_strength_weekness tr
{
  line-height: 18px !important;
  justify-content: center;
  align-items: center;
}
.pdf_score .page3_strength_weekness.act tr
{
  line-height: 20px !important;
}
.pdf_score .page3_strength_weekness.act .rpad
{
  line-height: 18px !important;
}

.col_address {
  padding: 0px;
  float: left;
  width: 68%;
}
.col_address.right {
  width: 32%;
}

.col_address {
  display: flex;
  flex-direction: column;
}

.col_address > div {
  display: flex;
  align-items: center;
}

.col_address > div span {
  margin-right: 5px; /* Adjust the margin as needed */
  color: #39327a;
}
.sections-score-wrapper div.block {
  width: 33%;
  float: left;
  text-align: center;
}
.rsatprogress-bar {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 94%, transparent 95% 100%),
    conic-gradient(#d1cfd9 30%, #ed197e 0);
}
.satprogress-bar1 {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  /* background: 
    radial-gradient(closest-side, white 94%, transparent 95% 100%),
    conic-gradient(#d1cfd9 30%, #ed197e 0); */
  border: 5px solid #ed197e;
}
.sc_head {
  font-size: 16px;
  padding-bottom: 30px;
  font-weight: bold;
}
.satprogress {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  line-height: 35px;
  margin: 0px auto;
  display: flex;
  padding-top: 12px;
  flex-direction: column;
}
.satprogress span {
  font-size: 50px;
  font-weight: bold;
  color: #3a2f7c;
}
.rsatprogress-bar2 {
  width: 190px;
  height: 190px;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 94%, transparent 95% 100%),
    conic-gradient(#d1cfd9 30%, #3a2f7c 0);
}
.satprogress-bar2 {
  width: 190px;
  height: 190px;
  border-radius: 50%;
  /*background: 
    radial-gradient(closest-side, white 94%, transparent 95% 100%),
    conic-gradient(#d1cfd9 30%, #3a2f7c 0);  */
  border: 5px solid #3a2f7c;
}
.satprogress-bar2 span {
  font-size: 60px;
  font-weight: bold;
  color: #ed197e;
}
.satprogress-bar3 {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  /*background: 
    radial-gradient(closest-side, white 94%, transparent 95% 100%),
    conic-gradient(#d1cfd9  30%, #ed197e 0); */
  border: 5px solid #ed197e;
}
.rsatprogress-bar3 {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: radial-gradient(closest-side, white 94%, transparent 95% 100%),
    conic-gradient(#d1cfd9 30%, #ed197e 0);
}

.actprogress-bar1 {
  width: 100px;
  height: 100px;
  border-radius: 50%; 
  border: 5px solid #ed197e;
} 
.actprogress {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  line-height: 35px;
  margin: 0px auto;
  display: flex;
  padding-top: 12px;
  flex-direction: column;
}
.actprogress span {
  font-size: 50px;
  font-weight: bold;
  color: #3a2f7c;
} 
.actprogress-bar2 {
  width: 140px;
  height: 140px;
  border-radius: 50%; 
  border: 5px solid #3a2f7c;
}
.actprogress-bar2 span {
  font-size: 60px;
  font-weight: bold;
  color: #ed197e;
}
.actprogress-bar3 {
  width: 100px;
  height: 100px;
  border-radius: 50%; 
  border: 5px solid #ed197e;
}
.scores {
  width: 80%;
  margin: 0px auto;
  overflow: hidden;
}
.scores .reading {
  float: left;
  width: 200px;
  background: #fffdfd;
  padding: 10px;
  font-size: 14px;
  line-height: 27px;
}
.scores .reading .rows_read .title {
  float: left;
  width: 80%;
  border-right: 2px solid #f7f5f5;
}
.scores .reading .rows_read .count {
  float: left;
  margin-left: 5%;
  text-align: right;
  font-weight: bold;
}
.scores .math {
  float: right;
  width: 200px;
  background: #fffdfd;
  padding: 10px;
  font-size: 14px;
  line-height: 27px;
}

.scores .math .rows_read .title {
  float: left;
  width: 80%;
  border-right: 2px solid #f7f5f5;
}
.scores .math .rows_read .count {
  float: left;
  margin-left: 5%;
  text-align: right;
  font-weight: bold;
}
.satgreen {
  color: #0e7642;
  font-weight: bold;
}
.satred {
  color: #ff0000;
  font-weight: bold;
}
.satorange {
  color: #ff6622;
  font-weight: bold;
}
.quiz-reports-tab-wrapper {
  padding: 0px 20px;
}
.topics_style_display {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}
.topics_style_display .topicblock {
  display: flex;
  font-size: 13px;
  align-content: center;
  align-items: center;
}
.topics_style_display .topicblock .percentage {
  width: 5%;
  background: #07c4d97a;
  text-align: center;
  margin-right: 10px;
  color: #fff;
  font-weight: bold;
}
.topics_style_display ul {
  margin: 0px;
  padding: 0px;
}
.topics_style_display ul li {
  border-top: 2px solid #eeeded;
  margin-top: 10px;
  padding: 10px 0px 0px;
  font-size: 13px;
}
.topics_style_display .topicblock .topic_title {
  width: 40%;
}
.topics_style_display .topicblock .status_bar {
  width: 40%;
}
.topics_style_display .topicblock .status_bar .progress {
  width: 180px;
  height: 7px;
  border: 0px solid black;
  background: linear-gradient(to right, #c8cac7 50%, #c5f7f7 50%);
  border-radius: 0px;
}
.topics_style_display .topicblock .percentage2 {
  width: 5%;
  background: #9191917a;
  text-align: center;
  margin-right: 10px;
  color: #fff;
  font-weight: bold;
  margin-left: 2%;
}
.topics_style_display .topicblock .count {
  width: 15%;
}
.satmods {
  margin-top: 20px;
  overflow: hidden;
}
.satmods .rounds {
  width: 48%;
  border: 1px solid #403680;
  border-radius: 4px;
  overflow: hidden;
  float: left;
}
.satmods .rounds.noborder {
  border: 0px solid #403680;
}
.satmods .rounds h3 {
  color: #000;
  font-size: 30px;
  text-align: center;
  padding: 0px 0px 10px;
  margin: 0px;
}
.satmods .rounds:nth-child(2) {
  float: right;
}
.satmods .rounds .final_score {
  text-align: center;
  color: #fff;
  font-size: 20px;
  width: 100%;
  overflow: hidden;
  background-color: #777476;
}
.padd
{
  padding:0px 2px 0px 5px;
}
.satmods .rounds .rwtitle {
  background-color: #fbfafb;
  text-align: center;
  padding: 5px 0px;
  color: #000;
  font-weight: bold;
  letter-spacing: 0px;
  padding-left: 0%;
}
.invdiv {
  padding: 10px 20px 10px;
  line-height: 30px;
  overflow: hidden;
  font-size: 14px;
}
.satmods .rounds .rowround {
  border-bottom: 1px solid #ccc;
  text-align: center;
  overflow: hidden;
}
.satmods .rounds .rowround .colname:first-child {
  width: 40%;
}
.satmods .rounds .rowround .colname {
  text-align: left;
  float: left;
  width: 20%;
  font-size: 16px;
  padding: 8px 0px 8px 10px;
}
.satmods .rounds .scol1 {
  float: left;
  width: 50%;
  border-right: 2px solid #f7f5f5;
  margin: 5px 0px;
}
.satmods .rounds .scol1:nth-child(2) {
  border-right: 0px solid #f7f5f5;
}
.satmods .rounds .scol1 .status {
  float: left;
  width: 70%;
}
.satmods .rounds .scol1 .counts {
  float: left;
  width: 30%;
  text-align: right;
}

.satmods .rounds .chart {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0px;
  font-size: 12px;
  border: 1px solid #403680;
  border-radius: 5px;
}

.satmods .rounds .bar-container {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
}

.satmods .rounds .label {
  width: 50px; /* Set a fixed width for the labels */
  margin-right: 10px;
}

.satmods .rounds .bar-wrapper {
  flex: 1;
}

.satmods .rounds .bar {
  background-color: #3a2f7c;
  color: #fff;
  padding: 4px;
  border-radius: 0px;
  transition: width 0.5s ease;
}
.satmods .rounds .bar-container:nth-child(3) .bar,
.satmods .rounds .bar-container:nth-child(4) .bar {
  background-color: #ed197e;
}

.actmods {
  margin-top: 40px;
  overflow: hidden;
}
.actmods .rounds {
  width: 100%;
  border: 1px solid #403680;
  border-radius: 4px;
  overflow: hidden;
  float: left;
}
.actmods .rounds.noborder {
  border: 0px solid #403680;
}
.actmods .rounds h3 {
  color: #000;
  font-size: 30px;
  text-align: center;
  padding: 0px 0px 10px;
  margin: 0px;
}
.actmods .rounds:nth-child(2) {
  float: right;
}
.actmods .rounds .final_score {
  text-align: center;
  color: #fff;
  font-size: 20px;
  width: 100%;
  overflow: hidden;
  background-color: #777476;
}
.padd
{
  padding:0px 2px 0px 5px;
}
.actmods .rounds .rwtitle {
  background-color: #fbfafb;
  text-align: center;
  padding: 5px 0px;
  color: #000;
  font-weight: bold;
  letter-spacing: 0px;
  padding-left: 0%;
}
.invdiv {
  padding: 10px 20px 10px;
  line-height: 30px;
  overflow: hidden;
  font-size: 14px;
}
.actmods .rounds .rowround {
  border-bottom: 1px solid #ccc;
  text-align: center;
  overflow: hidden;
}
.actmods .rounds .rowround .colname:first-child {
  width: 20%;
}
.actmods .rounds .rowround .colname {
  text-align: left;
  float: left;
  width: 16%;
  font-size: 16px;
  padding: 8px 0px 8px 10px;
}
.actmods .rounds .scol1 {
  float: left;
  width: 50%;
  border-right: 2px solid #f7f5f5;
  margin: 5px 0px;
}
.actmods .rounds .scol1:nth-child(2) {
  border-right: 0px solid #f7f5f5;
}
.actmods .rounds .scol1 .status {
  float: left;
  width: 70%;
}
.actmods .rounds .scol1 .counts {
  float: left;
  width: 30%;
  text-align: right;
}

.actmods .rounds .chart {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 12px;
  border: 1px solid #403680;
  border-radius: 5px;
}

.actmods .rounds .bar-container {
  display: flex;
  align-items: center;
  margin-bottom: 45px;
}

.actmods .rounds .label {
  width: 50px; /* Set a fixed width for the labels */
  margin-right: 10px;
}

.actmods .rounds .bar-wrapper {
  flex: 1;
}

.actmods .rounds .bar {
  background-color: #3a2f7c;
  color: #fff;
  padding: 4px;
  border-radius: 0px;
  transition: width 0.5s ease;
}
.actmods .rounds .bar-container:nth-child(3) .bar,
.actmods .rounds .bar-container:nth-child(4) .bar {
  background-color: #ed197e;
}
.satfooter {
  font-size: 8px;
  padding: 20px 0px;
}
.satfooter .left {
  float: left;
}
.satfooter .right {
  text-align: right;
  float: right;
}

.satmods .rounds .percentage {
  margin-left: 10px;
}

/* Bar Graph Vertical */
.bar-graph .year {
  -webkit-animation: fade-in-text 2.2s 0.1s forwards;
  -moz-animation: fade-in-text 2.2s 0.1s forwards;
  animation: fade-in-text 2.2s 0.1s forwards;
}

.bar-graph-vertical
{
  margin-top: 29px;
  max-width: 100%;
}

.bar-graph-vertical .bar-container {
  float: left;
  height: 150px;
  margin-right: 8px;
  position: relative;
  text-align: center;
  justify-content: center;
  width:22%;
}

.bar-graph-vertical .bar {
  border-radius: 3px;
  bottom: 40px;
  position: absolute;
  width: 40px;
  height: 0px !important;
}

.bar-graph-vertical .year {
  bottom: 0;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
} 
.bar-graph-vertical .scorereg {
  top: -45px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  font-size: 15px;
  font-weight: bold;
}

.bar-graph-two .bar::after {
  -webkit-animation: fade-in-text 2.2s 0.1s forwards;
  -moz-animation: fade-in-text 2.2s 0.1s forwards;
  animation: fade-in-text 2.2s 0.1s forwards;
  color: #fff;
  content: attr(data-percentage);
  font-weight: 700;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  text-align: left;
  top: 24px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.bar-graph-two .bar-one .bar {
  background-color: #3a2f7c;
  -webkit-animation: show-bar-one-vertical 1.2s 0.1s forwards;
  -moz-animation: show-bar-one-vertical 1.2s 0.1s forwards;
  animation: show-bar-one-vertical 1.2s 0.1s forwards;
}

.bar-graph-two .bar-two .bar {
  background-color: #3a2f7c;
  -webkit-animation: show-bar-two-vertical 1.2s 0.2s forwards;
  -moz-animation: show-bar-two-vertical 1.2s 0.2s forwards;
  animation: show-bar-two-vertical 1.2s 0.2s forwards;
}

.bar-graph-two .bar-three .bar {
  background-color: #407286;
  -webkit-animation: show-bar-three-vertical 1.2s 0.3s forwards;
  -moz-animation: show-bar-three-vertical 1.2s 0.3s forwards;
  animation: show-bar-three-vertical 1.2s 0.3s forwards;
}

.bar-graph-two .bar-four .bar {
  background-color: #2e515f;
  -webkit-animation: show-bar-four-vertical 1.2s 0.4s forwards;
  -moz-animation: show-bar-four-vertical 1.2s 0.4s forwards;
  animation: show-bar-four-vertical 1.2s 0.4s forwards;
}

/* Bar Graph Vertical Animations */
@keyframes show-bar-one-vertical {
  0% {
    height: 0;
  }
  100% {
    height: 40%;
  }
}

@keyframes show-bar-two-vertical {
  0% {
    height: 0;
  }
  100% {
    height: 55%;
  }
}

@keyframes show-bar-three-vertical {
  0% {
    height: 0;
  }
  100% {
    height: 68%;
  }
}

@keyframes show-bar-four-vertical {
  0% {
    height: 0;
  }
  100% {
    height: 82%;
  }
}

@keyframes fade-in-text {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.loader-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.loader-center .loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}