/* Modal.css */
.modal-overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000000000000;
}
  
  .modal2 {
    background: white;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    top:30px;
    width:90%;
    min-height: 400px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
  