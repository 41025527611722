@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;600;700;800;900;1000&display=swap');

  body {
    margin: 0;
    font-size: 1rem !important;
    font-family: Roboto,sans-serif;
    min-height: 100vh; /* Set the body to at least the full height of the viewport */
    margin: 0; /* Remove default margin */
    display: flex;
    flex-direction: column;
    background:  #fbfafa !important;
  }
  .divdisabled
  {
    pointer-events: none;
    opacity: 0.4;
  }
  .welcome_text
  {
    text-transform: uppercase;
    font-weight: 500;
    color: #de3c7d;
    padding-right: 20px;
  }

  .resources {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .resources h1 {
    text-align: center;
    padding: 20px 0px 0px;
    font-size: 24px;
    text-transform: uppercase;
    color: #383178;
  }
  .resources h3 {
    text-align: center;
    padding: 20px 0px 0px;
    font-size: 24px;
    color: #383178;
    text-decoration: underline;
  }

  .resources h6 {
    text-align: center;
    padding: 10px 0px;
  }


  .resources header {
    background-color: #fff;
    color: #000;
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0px solid #DE3C7D;
    box-shadow: 0 1px 3px rgba(0,0,0,.4);
  }
  .resources .accordion-button:not(.collapsed) {
    color: #FFF;
    background-color: #DE3C7D;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  }
  .resources .nested .accordion-button:not(.collapsed)
  {
    color: #000;
    background-color: #FFF;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  }
  .resources .accordion-body {
    padding: 0px;
  }
  
  .resources .nested .accordion-body {
    padding: 20px;
  }

  .resources .nested .attempts {
    background: #383178;
    width: auto;
    border-radius: 20px;
    margin: 10px;
    color: #FFF;
    padding: 10px 20px;
  }
  .resources .nested .accordion-flush .accordion-item
  {
      border:0px !important;
  }

  .resources .logo {
    display: flex;
    align-items: center;
  }

  .resources .logo img {
    width: 150px; /* Adjust the width as needed */
    height: auto;
    margin-right: 10px;
  }

  .resources nav {
    display: flex;
    align-items: center;
  }

  .resources nav a {
    color: #000;
    font-weight: 500;
    text-decoration: none;
    margin-right: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
  .resources nav a:hover
  {
    color:#DE3C7D;
  }
  .forgot_chkbox
  {
    float: left;
    margin-right: 5px;
    width: 15px !important;
    margin-top: -2px;
  }
  .resources .favicon
  {
    width: 35px;
    padding: 5px 5px;
    height: auto;
    border: 2px solid lightgray;
    margin-right: 10px;
    border-radius: 50%; /* Adjust the border-radius as needed */
  }
  .more-button {
    margin-left: 20px;
    cursor: pointer;
  }
  
  .dropdown-content 
  {
      position: absolute;
      background-color: #f9f9f9;
      min-width: 81px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 1;
      padding: 8px;
      border-radius: 4px;
      right: 8px;
      text-align: left;
  }
  .dropdown-content button, .dropdown-content a
  {
    font-size: 13px;
    border: 0px;
    text-align: center;
    color:#000;
  }
  
  .more-button:hover + .dropdown-content {
    display: block;
  }
  .resources .favicon img
  {
    width:100%;
  }
  .resources nav a i {
    margin-right: 5px;
  }
  .column1_max, .column2_max
  {
    width: 25px;
    padding-bottom: 25px;
  }
  .resources .middle_block
  {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 60px;
    background:0 1px 3px rgba(0,0,0,.4);
  }
  .resources .middle_container
  {
    width: 1140px; /* Adjust the width as needed */
    margin:20px auto;
    background-color: #f2f2f1;
    border-radius: 10px; /* Adjust the border-radius as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust the shadow as needed */
    padding: 0px; 
  }
  .resources.courses .middle_container
  {
    width: 1140px; /* Adjust the width as needed */
    margin:20px auto;
    background-color: #fff;
    border-radius: 0px; /* Adjust the border-radius as needed */
    box-shadow: none; /* Adjust the shadow as needed */
    padding: 0px; 
  }
  .resources .container {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Adjust the width as needed */
    padding: 0px;
    margin: 0px;
  }

  .resources .container2 {
    display: flex;
    justify-content: space-between;
    width: 50%; /* Adjust the width as needed */
    margin: 0px auto !important;
    padding: 0px;
  }

  .resources .courses_container {
    display: flex;
    justify-content: space-between;
    width: 90%; /* Adjust the width as needed */
    margin: 0px auto !important;
    padding: 0px;
  }

  .resources .courses_container  .block
  {
    width:50%;
  }

  .resources .block {
    flex: 1;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px;
    margin: 10px;
    text-align: center;
  }

  .resources .block i {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 10px;
    background: #DE3C7D;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px auto 20px;
    color: #fff; /* Set text color to white or your preferred color */
  }

  .resources .block h2 {
    margin-bottom: 10px;
    font-size: 1rem;
  }

  .resources .block span {
    text-decoration: none;
    color: #007BFF; /* Adjust the link color as needed */
    display: block;
    margin-bottom: 10px;
  }

  .resources .container2 .block h2 {
    margin-bottom: 10px;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
 }
 .resources .container2 .block span {
    text-decoration: none;
    color: gray; /* Adjust the link color as needed */
    display: block;
    margin-bottom: 10px;
    font-size: 11px;
  }
  .resources .container2 .block .card-body
  {
    background: #6bc977;
    margin: 0px auto 20px;
    border-radius: 7px;
    color: #FFF;
    font-weight: bold;
    padding:10px 0px;
  }

  .resources .display-4 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
    color:#FFF;
  }

  .resources .container2 .block i 
  {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 10px;
        background: green;
        width: 35px;
        height: 35px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: -30px auto 20px;
        color: #fff;
        font-size: 24px;
}

.resources .block .button {
        padding: 5px 20px;
        background-color: #DE3C7D;
        color: #fff;
        border: none;
        border-radius: 25px;
        cursor: pointer;
  }
  .resources .nav-buttons {
    justify-content: space-between;
    align-self: center;
    width:100%;
    overflow: hidden;
  }

  .resources .nav-buttons a {
    padding: 10px 20px;
    float: right;
    background-color: #198754;
    color: #fff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
  }

  .resources .container .block .button {
    padding: 5px 20px;
    background-color: transparent;
    color: #DE3C7D;
    border: none;
    border-radius: 25px;
    cursor: default;
    border: 1px solid #DE3C7D;
}
.resources .footer {
  flex-shrink: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #fff;
  line-height: 60px;
  border-top: 0px solid #DE3C7D;
  padding: 20px 0px 10px;
  text-align: center;
  font-size: 14px;
  box-shadow: 0 -2px 3px rgba(0,0,0,.4);
}
  .resources .collapse {
    visibility: inherit;
  }

  /*MAIN RESOURECES*/

   .mresources
   {
      width: 1140px; /* Adjust the width as needed */
      margin:0px auto;
      padding: 0px;
      margin-top: 25px;
   }

  .mresources .blocks {
    display: flex;
    align-items: top;
    justify-content: space-between;
    padding: 10px 10px;
    border: 0px solid #ccc;
    background-color: #fff;
    border-radius: 5px; /* Adjust the border-radius as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust the shadow as needed */
    margin: 0px 0px 30px 0px;
  }
  .mresources .blocks.active, .mresources .blocks:hover
  {
    background-color: #383178;
    color: #FFF;
    cursor: pointer;
  }
  .mresources .blocks .icon, .mresources .blocks .arrow-down
  {
    text-align: center;
    width: 50px;
    vertical-align: text-top;
  }
  
  .mresources .blocks > div {
    text-align: center;
  }

  .mresources .blocks .content
  {
    width: 100%;
    display: block;
    margin: 0px;
    padding: 0px;
    text-align: left;
  }
  .mresources .blocks .content h3
  {
    text-align: left;
    text-decoration: none;
    margin: 0px;
    padding: 0px 0px 5px;
    font-size: 18px;
  }
  .mresources .blocks .content p {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    line-height: 25px;
  }
  
  .mresources .blocks i {
    font-size: 24px;
    margin-bottom: 10px;
    color: #5bbffb;
  }
  .mresources .blocks.active i, .mresources .blocks:hover i
  {
    color: #FFF;
  } 

  .mresources .blocks.active h3, .mresources .blocks:hover h3
  {
    color: #FFF;
  }

  .segment1 {
    flex: 0.1; /* Take up 30% of the available space */
    background-color: #de3c7d;
  }
  
  .segment2 {
    flex: 0.9; /* Take up 70% of the available space */
    background-color: #54a5e3;
  }
  

  .status-bar {
    display: flex;
    height: 5px;
    width: 100%;
    margin-top: 5px;
  }

  .status-segment {
    height: 100%;
    transition: background-color 0.3s ease;
  }

  .mresources .blocks div:nth-child(2) {
    text-align: left;
  }
  
  /* Add additional styling as needed */
  .practice_tests
   {
      width: 100%;
      margin: 0px auto;
      margin-top: 0px;
      padding: 10px 25px;
      margin-top: 25px;
      background: white;
      border-radius: 5px;
      min-height: 600px;
   }

  .practice_tests .blocks {
    display: flex;
    align-items: top;
    justify-content: space-between;
    padding: 10px 10px;
    border: 0px solid #ccc;
    border-radius: 5px; /* Adjust the border-radius as needed */
    margin: 0px;
  }


   /* Container style */
   .practice_tests .test-container {
    border-bottom: 1px solid #ddd;
    padding: 15px;
    width: 100%; /* Adjust the width as needed */
    overflow:hidden;
  }

  /* Header style */
  .practice_tests .status {
    font-weight: normal;
    margin-bottom: 10px; 
    width: 30%;
    float: left;
    font-size: 14px;
    align-items: center; /* Vertically centers content */
}

  .practice_tests .status span
  {
    font-weight:700;
  }

  .practice_tests .score {
    font-weight: normal;
    margin-bottom: 10px; 
    width: 10%;
    float: left;
    font-size: 14px; 
    text-align: center;
    align-items: center; /* Vertically centers content */
    padding-top: 15px;
}

  .practice_tests .score span
  {
    font-weight:700;
  }

  /* Date style */
  .practice_tests .date {
    color: #555;
    margin-bottom: 10px;
    float:left;
    width:25%;
    font-size:13px;
    text-align: center;
    padding-top:15px;
  }

  /* Start button style */
  .practice_tests .start-button {
    background-color: #fff;
    color: #cc0c5d;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #cc0c5d;
    border-radius: 1px;
    float: right;
    width: 15%;
    font-size: 13px;
    text-align:center !important;
  }
  .practice_tests .actstart-button {
    background-color: #fff;
    color: #cc0c5d;
    padding: 10px;
    cursor: pointer;
    border: 1px solid #cc0c5d;
    border-radius: 1px;
    float: right;
    width: 100%;
    font-size: 13px;
    text-align:center !important;
  }

  .practice_tests .review-button {
    background-color: #fff;
    color: green;
    padding: 10px;
    cursor: pointer;
    border: 1px solid green;
    border-radius: 1px;
    float: right;
    text-align:center !important;
    width: 15%;
    font-size: 13px;
  }

  .practice_tests .actreview-button {
    background-color: #fff;
    color: green;
    padding: 10px;
    cursor: pointer;
    border: 1px solid green;
    border-radius: 1px;
    float: right;
    text-align:center !important;
    width: 100%;
    font-size: 13px;
  }

  .practice_tests .blocks.active
  {
    background-color: #7eb0fd;
    color: #000;
    cursor: pointer;
  }
  .practice_tests .blocks .icon, .practice_tests .blocks .arrow-down
  {
    text-align: center;
    width: 50px;
    vertical-align: text-top;
  }
  
  .practice_tests .blocks > div {
    text-align: center;
  }

  .practice_tests .blocks .content
  {
    width: 100%;
    display: block;
    margin: 0px;
    padding: 0px;
    text-align: left;
  }
  .practice_tests .blocks .content h3
  {
    text-align: left;
    text-decoration: none;
    margin: 0px;
    padding: 0px 0px 5px;
    font-size: 18px;
  }
  .practice_tests .blocks .content p {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    line-height: 25px;
  }
  
  .practice_tests .blocks i {
    font-size: 24px;
    margin-bottom: 10px;
    color: #5bbffb;
  }
  .practice_tests .blocks.active i, .practice_tests .blocks:hover i
  {
    color: #fff;
  } 
  .practice_tests .blocks .anchorclass {
    background: #0768ff;
    padding: 5px 10px 0px;
    border-radius: 5px;
    margin-top: 12px !important;
    display: block;
    font-size: 14px;
    color: #fff;
    line-height: 0px;
  }
  .dashed-border
  {
    background-image:url('strip.png');
    background-repeat: repeat-x;
    height: 12px !important;
    background-color: transparent;
    border: 0px !important;
  }
  .practice_tests .blocks .anchorclass i
  {
    font-size: 15px;
  }
  .sat_icon
  {
    padding-left: 10px;
  }
  .practice_tests .blocks.active h3, .practice_tests .blocks:hover h3
  {
    color: #000;
  }
  .downloadbutton
  {
    width: 100%;
    overflow: hidden;
    margin-top: 20px;
    padding-top: 7px;
  }