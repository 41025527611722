
#main-wrapper
{
    background:#fdfbfb;
}
.answer-bubble 
{
    background:#fdfbfb;
}

.test_scores_text
{
  width: 50%;
  text-align: center;
  margin: 20px auto;
  color:#000;
  padding: 20px;
  border: 1px solid #403680;
}
.test_scores_text2
{
  width: 80%;
  text-align: center;
  margin: 0px auto;
  color:#000;
  padding: 10px;
  border: 1px solid #403680;
}
.group-block-container {
    display: flex;
    justify-content: space-between;
    padding: 5px 0; 
  } 
  .group-block .group-block-header-container {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .group-block-container .group-block-stats-container {
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .group-block-dropdown-space {
    width: 25px;
    height: 23px;
  }
.page-content {
	max-width: 1140px; 
	margin: 0px auto; 
	padding: 0px; 
	background: #fff;
}
a {
	color: #21D4FD; 
	transition: all 0.3s;
}
a:hover {
	color: #B721FF;
}

.tabbed 
  {
    overflow-x: hidden;
    margin: 32px 0;
    padding-bottom: 16px;
  }

.tabbed [type="radio"] {
	/* hiding the inputs */
	display: none;
}
.custom-table
{
	color:#000;
}
.custom-table * {
    font-size: 14px;
}
.custom-table tr th
{
  color: #FFF;
  background-color: #42cff0;
  padding:5px 0px;
}
.problem-answer-cell .form-input {
    max-width: 150px;
    margin: 0;
    background: #fff;
}
.form-input #custom-form-input {
    padding: 10px;
    border: none;
    width: 100%;
    border: 2px solid #ededed!important;
    border-radius: 5px;
}
.progress-container {
	width: 100%;
	background: #ccc;
	height: 10px;
	border-radius: 4px;
	overflow: hidden;
  }
  
 .progress-bar {
	height: 100%;
	background: #4caf50; /* Green color for the progress */
	transition: width 0.3s ease;
 }
 .quiz-reports-tab-wrapper table th {
    background-color: #6973cd !important;
    text-align: left;
}
.report-questions table, .report-questions table tr th, .report-questions table tr td 
{
	border-width: 1px !important;
}
.report-questions table th {
    background: #0868D9;
    font-weight: bold;
    font-size: 15px;    
	padding: 5px 10px;
	color: #FFF !important;
}
.quiz-reports-nav li a.active {
    background: #6973cd;
    color: #fff;
}
.quiz-reports-nav li a {
    background-color: #F2F2F2;
}
.quiz-reports-nav li {
    display: block;
    margin: 0 7px 0 0;
    padding: 0;
    list-style: none;
    float: left;
    position: relative;
	margin: 0 5px 0 0;
}
.quiz-reports-nav li a {
    display: block;
    font-size: 14px;
    line-height: 1;
    padding: 10px 20px;
    margin-bottom: -16px;
    border-radius: 3px 3px 0 0;    
	border-radius: 8px;
	margin-bottom: 5px;  
	color: #333;
}
.quiz-report table tr {
    page-break-before: always;
    page-break-inside: avoid;
}
.quiz-report table tr {
    page-break-before: always;
    page-break-inside: avoid;
}
tr.odd {
    background-color: #f4f4f4;
}
.response_report_form div table {
	border-width: 1px;
	width: 100%;
  }
  .response_report_form div table td
  {
	border-width: 1px;
	padding:10px  10px;
  }
  .check_answer_attempted input {
    background-color: #6973cd !important;
    padding: 11px 30px !important;
    border-radius: 10px !important;
    border: none !important;
	color:white;
}
.quiz-report-question-head {
    display: block;
    margin-right: 90px;
    margin-left: 0px;
    font-size: 16px;
    color: #4E4E4E;
    text-align: justify;
}
.quiz_result_section_wrapper {
    border: 1px solid #ccc;
    padding: 10px;
    position: relative;
    clear: both;
}
.quiz-report-question-title {
    padding: 5px;
    float: left;
    padding-right: 22px;
    font-size: 16px;
    font-weight: bold;
	padding: 0px 3px 3px 0 !important;
}
.quiz-report-question-head {
    margin-left: 51px;
}
.quiz-report-question-head {
    display: block;
    margin-right: 90px;
    margin-left: 0px;
    font-size: 16px;
    color: #4E4E4E;
    text-align: justify;
}
.quiz-reports-tab-wrapper .score_card {
	max-width: 50%;
	margin-left: 2em;
	margin-top: 30px;
  }
 #wrapper .quiz-reports-tab-wrapper table th{background-color:#6973cd !important;text-align:center;}.quiz-reports-tab-wrapper .quiz-reports-nav .button{background-color:#6973cd !important;}.quiz-reports-tab-wrapper .main_score_accuracy{width:328px;height:160px;margin-left:33%;margin-top:-15px;}.quiz-reports-tab .score_card .time-manage{margin-right:15%;}.quiz-reports-tab .score_card .tot-questions{margin-right:70%;margin-bottom:9%;}#test-series-view .quiz-reports-tab-wrapper .main_score_accuracy{width:359px;height:175px;margin-left:142px}#test-series-view .quiz-reports-tab .score_card .time-manage{margin-right:unset;}#test-series-view .quiz-reports-tab .score_card .tot-questions{margin-right:unset;}#quiz-question-answering-form .quiz-question-body .question_title li,#question_node #edit-body-wrapper li,#quiz-report-form .quiz-report-question-head li{list-style:disc !important;}.quiz-reports-tab-wrapper .reports-legend{margin-left:-19px;margin-right:-46%;}
.pill {
    min-width: 40px;
    max-width: 50px;
    padding: 3px 6px 4px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    margin: auto;
    box-sizing: border-box!important;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.pill.border-pill {
    background-color: #fff!important;
    border-width: 2px;
    border-style: solid;
}
.pill.border-pill.green {
    border-color: #6ab23d;
    color: #6ab23d;
}
.custom-table .custom-table-row {
    padding: 10px 0;
    margin: 10px 0;
    min-height: 50px;
    border-bottom: 1px solid rgba(0,0,0,.12);
}
.custom-table tbody tr {
    border-bottom: 1px solid rgba(0,0,0,.12) !important;
}
.custom-table-row td {
	padding: 10px 0px;
}
.tabbed .innertabs [type="radio"] {
	/* hiding the inputs */
	display: block !important;
}
.problem-answer-cell {
    width: 100%;
    display: flex;
}
.problem-answer-cell .problem-answers-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.problem-answer-cell .problem-answers-container .answer-bubble {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-style: solid;
    border-width: 0;
    box-shadow: inset 4px 3px 10px -1px rgba(50,50,50,.15), 2px 2px 3px 0 rgba(0,0,0,.15);
    background: #fff;
    cursor: pointer;
}
.problem-answer-cell .problem-answers-container .answer-bubble.correct {
    border-color: green;
    border-width: 1px;
    color: white;
    background: green;
    font-weight: normal;
}
.problem-answer-cell .problem-answers-container .answer-bubble.wrong {
    border-color: red;
    border-width: 1px;
    color: white;
    background: red;
    font-weight: normal;
}
.form-input #custom-form-input.correct-border {
    border: 2px solid green !important;
}

.form-input #custom-form-input.incorrect-border {
    border: 2px solid red !important;
}
container .answer-bubble {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-style: solid;
    border-width: 0;
    box-shadow: inset 4px 3px 10px -1px rgba(50,50,50,.15), 2px 2px 3px 0 rgba(0,0,0,.15);
    background: #fff;
    cursor: pointer;
}
.problem-answer-cell .problem-answers-container .answer-bubble div {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tabs {
	display: flex;
	align-items: stretch;
	list-style: none;
	padding: 0;
	border-bottom: 1px solid #ccc;

}
.tabs li
{
    width:33.3333%;
    float: left;
    text-align: center;
    padding:5px 0px;
    border-right: 1px solid #CCC;
    background: #fff7da;
} 
.tabs li:last-child
{
  border-right:0px;
}
.tabs li.active {
  background: #e6d7e6;
  color: #000;
  border-color: #fff;
}

.tab > label {
	display: block;
	margin-bottom: -1px;
	padding: 12px 15px;
	border: 0px solid #ccc;
	background: transparent;
	color: #000 !important;
	font-size: 18px; 
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: 1px;
	cursor: pointer;	
	transition: all 0.3s;
}
.tab:hover  {
  background: #e6d7e6;
  color: #000;
}
.tabs li.active > label, .tabs li:hover > label
{
	color: #000 !important;
}

.tab-content {
	color: #777;
  padding:0px !important;
}

/* As we cannot replace the numbers with variables or calls to element properties, the number of this selector parts is our tab count limit */
.tabbed [type="radio"]:nth-of-type(1):checked ~ .tabs .tab:nth-of-type(1) label,
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tabs .tab:nth-of-type(2) label,
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tabs .tab:nth-of-type(3) label,
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tabs .tab:nth-of-type(4) label,
.tabbed [type="radio"]:nth-of-type(5):checked ~ .tabs .tab:nth-of-type(5) label {
	border-bottom-color:transparent;
	border-top-color: transparent;
	background: transparent;
  border:0px;
}

.tabbed [type="radio"]:nth-of-type(1):checked ~ .tab-content:nth-of-type(1),
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tab-content:nth-of-type(2),
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tab-content:nth-of-type(3),
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tab-content:nth-of-type(4) {
	display: block;
} 

.loader {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
  
    & .loader-rings-container {
      width: max-content;
      position: relative;
    }
  
    & .loader-rings {
      transform: rotate(180deg);
      width: 100%;
      height: 100%;
      stroke-width: 7;
      fill: transparent;
    }
  
    & .loader-ring {
      transition: 0.8s stroke-dashoffset;
      transform: rotate(-90deg);
      transform-origin: 50% 50%;
    }
  
    & .loader-text-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      transform: translate(-50%, -50%);
      gap: 5px;
  
      div {
        margin: 2px;
      }
    }
  
    & .delta {
      color: #bbb;
      font-size: 18px;
    }
  
    & .loader-text {
      font-size: 20px;
      text-align: center;
      font-weight: 300;
      white-space: break-spaces;
  
      & .additional {
        color: #9f9f9f;
      }
    }
  
    & .loader-score {
      font-size: 42px;
      font-weight: 300;
      line-height: 1;
      width: 100%;
      text-align: center; 
  
      @media (max-width: 900px) {
        font-size: 40px;
      }
    }
  }

  .summary-header-container {
    margin-bottom: 20px;
    text-align: center;
  
    h2 {
      font-size: 19px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  
    @media (max-width: 900px) {
      h2 {
        font-size: 17px;
      }
    }
  }
  
  .summary-annotation {
    font-weight: normal;
    font-size: 15px;
  }
  
  .summary-loader  {
    position: relative;
  }
  
  .summary-loaders {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top:20px;
  
    @media (max-width: 1270px) {
      @media (max-width: 1400px) {
        display: flex;
      }
  
      @media (max-width: 1000px) {
        .summary-loader {
          margin: 20px;
        }
      }
    }
  
    .summary-loader {
      margin: 0 20px;
      position: relative;
    }
  }
  
  .summary-content {
    background: white;
    padding: 40px;
    margin-bottom: 20px;
    box-shadow: none;
    border: 2px solid light-grey;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    border-radius: 5px;
    width: 100%;
  
    @media (max-width: 1400px) {
      flex-direction: column;
    }
   .summary-dashboard {
      .summary-scores--multiple {
        margin-right: 5%;
  
        @media (max-width: 1400px) {
          margin-right: 0;
        }
      }
    }
  }
  
  .summary {
    display: flex;
    flex-direction: column;
  
    & .summary-row {
      display: flex;
      flex-direction: row;
  
      @media (max-width: 1400px) {
        flex-direction: column;
      }
    }
  
    & .summary-label {
      font-weight: 600 !important;
      font-size: 17px !important;
      margin-bottom: 10px;
    }
  
    .breaker-container {
      height: 100%;
      display: block;
      border: 2px solid lightgrey;
  
      @media (max-width: 1400px) {
        display: none;
      }
    }
  }

.small-radius {
  min-width: 40px !important;
  border-radius: 4px !important;
  margin-right: 10px !important;
  font-size: 13px !important;
  line-height: 1.5 !important;
  padding: 2px 3px !important;
}

.orange {
  background-color:  orange !important;
}

.light-green {
  background-color:  green !important;
}

.red {
  background-color:  red !important;
}

.white-text {
  color: white !important;
}
.custom-table-row {
  position: relative;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.fa-ellipsis-v {
  cursor: pointer;
}

.dropdown-tmenu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 8px;
  display:block;
}

.dropdown-tmenu button {
  display: block;
  width: 100%;
  padding: 8px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-align: left;
}

.dropdown-tmenu button:hover {
  background-color: #f5f5f5;
}
.questions_overview .group-block
{
  width: 33%;
  float: left;
  text-align: center;
  padding: 20px 0px;
}
.questions_overview .group-block div.number {
  border-radius: 5px;
  padding: 5px;
  font-size: 20px;
  background-color: #383178;
  font-weight: bold;
  color: #FFF;
  overflow: hidden;
  margin-bottom: 10px;
  display: block;
  width: 40px !important;
  float: none;
  height: 40px;
  margin: 0px auto 10px;
}
.review-popup {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0px;
  background-color: #fff;
  border: 2px solid #000;
  z-index: 1000;
  width:640px;
  height:500px;

}
.pdf_score { 
    border: 0px solid #ff0000;
    padding: 0px;
    margin: 20px 0;
}
@media print {
  .pdf_score { 
      page-break-before: always; 
      /* Additional styling for demonstration purposes */
      border: 0px solid #ff0000;
      padding: 0px;
      margin: 0px 0;
      min-height:1125px;
  }
}
.fixwidth {
  width: 20px;
  text-align: right;
  display: block;
}
/* Additional styles for demonstration purposes */
body {
  font-family: Arial, sans-serif;
}